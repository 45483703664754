<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <v-progress-circular
      v-if="loading"
      :size="100"
      :width="4"
      color="fmq_black"
      indeterminate
      class="d-flex mx-auto py-8 mt-8"
    ></v-progress-circular>
    <div v-else>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <v-btn
            small
            depressed
            color="fmq_gray"
            dark
            outlined
            to="/banco-de-cartas-ic"
          >
            <v-icon small> mdi-arrow-left </v-icon>Voltar</v-btn
          >
        </div>
        <v-row>
          <v-col cols="8">
            <ModelosFormulario
              :modelo="modelo"
              :loadingBtn="loadingBtn"
              :artigos="modelo.artigos"
              :listTags="listTags"
              :listCategorias="listCategorias"
              :listArtigos="listArtigos"
              :listProdutos="listProdutos"
          /></v-col>
          <v-col cols="4">
            <ModelosLogsEditar :logs="modelo.logs" />
          </v-col>
        </v-row>
      </div>
    </div>
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { listarArtigos } from "@/services/artigos.js";
import { listarProdutos } from "@/services/produtos.js";
import { listarTags } from "@/services/tags.js";
import { listarCategoria } from "@/services/categorias.js";
import { exibirModelo } from "@/services/modelos.js";
import ModelosFormulario from "@/components/modelos/ModelosFormulario.vue";
import ModelosLogsEditar from "@/components/modelos/ModelosLogsEditar.vue";
export default {
  name: "BancoDeCartasICVisualizar",
  components: { ModelosFormulario, ModelosLogsEditar },
  data: () => ({
    breadcrumbs: [
      {
        text: "Banco de cartas",
        disabled: false,
        to: "/banco-de-cartas-ic",
      },
      {
        text: "Visualizar Modelo de Resposta",
        disabled: true,
        to: "",
      },
    ],
    error: false,
    success: false,
    loadingBtn: false,
    messageError: null,
    modelo: {},
    loading: false,
    listCategorias: [],
    listTags: [],
    listProdutos: [],
    listArtigos: [],
  }),
  beforeCreate() {
    if (this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({ path: "/" });
    }
  },
  created() {
    this.exibir();
    this.getProducts();
    this.getCategories();
    this.getTags();
    this.getArticles();
  },
  methods: {
    async exibir() {
      this.error = false;
      this.loading = true;
      try {
        const resp = await exibirModelo(this.$route.params.id);
        this.modelo = resp.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    async getCategories() {
      const resp = await listarCategoria();
      this.listCategorias = resp.data;
    },
    async getTags() {
      const resp = listarTags();
      this.listTags = resp.data;
    },
    async getProducts() {
      const resp = await listarProdutos();
      this.listProdutos = resp.data;
    },
    async getArticles() {
      const resp = await listarArtigos();
      this.listArtigos = resp.data;
    },
  },
};
</script>

<style></style>
